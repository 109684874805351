// this.getMovim-js

// Este mix in va a contener un solo método. tempg
// Recibe un arreglo y lo regresa con los calculos de la grid de ventas f4.

import { mapGetters, mapState, mapActions } from 'vuex'
var accounting = require('accounting')

export default {

  data() {
    return {
      tempg: [],
      importedoc: 0.00,
      descuentodoc: 0.00,
      total: 0.00,
      iva: 0.00,
      subtotal: 0.00

    }
  },

  computed: {
    ...mapGetters('carrito', ['getCarrito', 'getPaquetes']),
    ...mapState('carrito', ['carrito']),
    ...mapGetters("calculoEnvios", ["getEnvios"]),
    ...mapGetters("tipoemp", ["getTipoemp"]),
  },

  methods: {
    ...mapActions("carrito", ["setPaquetes","setPaquete","delPaquete","setCostoEnvio",
    ]),

    formarCarrito() {
      // 1. Vamos a manejar igual que en Sait Tempg para grid de la venta.
      // Se manda llamar desde el Método al mixins.
      // console.log("formarcarrito", this.getCarrito)
      // 2. Unformar para poder usar los numericos.
      for (var i = this.getCarrito.length - 1; i >= 0; i--) {
        this.getCarrito[i].importe = accounting.unformat(this.getCarrito[i].precio)
        this.getCarrito[i].precio = accounting.unformat(this.getCarrito[i].precio)
      }

      // OBJETO DOCUM
      this.total = 0
      this.iva = 0
      this.subtotal = 0
      this.descuentodoc = 0
      this.importedoc = 0
      // console.log("Carrito", this.getCarrito)

      // hacer el subtotal, iva y total
      for (var i = this.getCarrito.length - 1; i >= 0; i--) {
        // console.log("Recorriendo getCarrito", this.getCarrito[i])
        // console.log("cantidad", this.getCarrito[i].cantidad)
        // console.log("pjedesc", this.getCarrito[i].pjedesc)
        // console.log("impuesto1", this.getCarrito[i].impuesto1)

        // Importe = Movim.CANT*Movim.PRECIO
        var importepar = this.getCarrito[i].cantidad * this.getCarrito[i].precio
        // console.log("Importe ", importepar)

        // descuento = Sum(Movim.CANT*Movim.PRECIO *Movim.PJEDESC *.01
        var descuento = this.getCarrito[i].cantidad * this.getCarrito[i].precio * this.getCarrito[i].pjedesc * 0.01
        // console.log("Descuento ", descuento)

        // SubTotal  = Sum(Movim.CANT*Movim.PRECIO*(1-Movim.PJEDESC *.01)
        var subtotalpar = this.getCarrito[i].cantidad * this.getCarrito[i].precio * (1 - this.getCarrito[i].pjedesc * 0.01)
        // console.log("subtotalpar", subtotalpar)

        // IVA = Sum(Movim.CANT*Movim.PRECIO*(Movim.IMPUESTO1 *.01)*(1-Movim.PJEDESC*.01)) As IVA,
        var ivapar = this.getCarrito[i].cantidad * this.getCarrito[i].precio * (this.getCarrito[i].impuesto1 * 0.01) * (1 - this.getCarrito[i].pjedesc * 0.01)
        // console.log("iva par", ivapar)

        // Total = Sum(Movim.CANT*Movim.PRECIO*(1+Movim.IMPUESTO1 *.01)*(1-Movim.PJEDESC*.01)
        var totalpar = this.getCarrito[i].cantidad * this.getCarrito[i].precio * (1 + this.getCarrito[i].impuesto1 * 0.01) * (1 - this.getCarrito[i].pjedesc * 0.01)
        // console.log("totalpar",totalpar)

        // Actualizar vuex
        this.getCarrito[i].importe = importepar
        this.getCarrito[i].descuento = descuento
        this.getCarrito[i].subtotal = subtotalpar
        this.getCarrito[i].iva = ivapar
        this.getCarrito[i].total = totalpar

        // Calcular Totales
        this.importedoc = this.importedoc + importepar
        this.descuentodoc = this.descuentodoc + descuento
        this.subtotal = this.subtotal + subtotalpar
        this.iva = this.iva + ivapar
        this.total = this.total + totalpar

        // Dar Formato.
        this.getCarrito[i].total = accounting.formatNumber(this.getCarrito[i].total, 2)
        this.getCarrito[i].total = accounting.formatNumber(this.getCarrito[i].total, 2)
        // this.getCarrito[i].importe = accounting.formatNumber((this.getCarrito[i].precio * this.getCarrito[i].cantidad), 2)
        this.getCarrito[i].importe = accounting.formatNumber(this.getCarrito[i].importe, 2)
        this.getCarrito[i].precio = accounting.formatNumber(this.getCarrito[i].precio, 2)
      }

      // DAR FORMATO
      this.total = accounting.formatNumber(this.total, 2)
      this.iva = accounting.formatNumber(this.iva, 2)
      this.subtotal = accounting.formatNumber(this.subtotal, 2)
      this.descuentodoc = accounting.formatNumber(this.descuentodoc, 2)
      this.importedoc = accounting.formatNumber(this.importedoc, 2)

      this.formarpaquetes()
    },

    formarpaquetes() {
      // console.log("this.getCarrito", this.getCarrito)
      // console.log("this.getPaquetes", this.getPaquetes)
      // console.log("getTipoemp", this.getTipoemp)

      let paquetes = []

      // 1. Recorrer el carrito. Y juntar elementos del mismo tipo.
      this.getCarrito.forEach(element => {
        this.getTipoemp.forEach(tipoe => {

          if (element.tipoemp === tipoe.tipoemp) {
            // console.log("paquetes", paquetes)
            let lnuevo = true
            lnuevo = paquetes.find(paq => paq.tipoemp == element.tipoemp);
           
            // console.log("empaque nuevo", lnuevo)
            // Nuevo
            // if (buscarEmpaque == undefined) {
            if (lnuevo === undefined ) {

              // console.log("buscaempaque nuevo")  

              paquetes.push({
                tipoemp: element.tipoemp,
                cantMax: tipoe.cantmax,
                CostoEnvio: tipoe.costoenv,
                cantPaq: 1,
                cantActTipoEmp: element.cantidad
              })
            } else {
              // console.log("ya existe" , lnuevo)
              // buscar en paquetes el indice de tipoemp
              // paquetes.cantidad = paquetes.cantidad + element.cantidad
              paquetes.forEach(tipocaja => {
                if (tipocaja.tipoemp === element.tipoemp) {
                  tipocaja.cantActTipoEmp = tipocaja.cantActTipoEmp + element.cantidad

                  //NUEVO
                  if (tipocaja.cantMax === 0 || tipocaja.cantMax === undefined) {
                    tipocaja.cantMax = 1
                  }
                  tipocaja.cantPaq = Math.ceil(
                    tipocaja.cantActTipoEmp / tipocaja.cantMax
                  );

                }
              })
            }
          }

        })
      });

      // console.log("Sustituir todo el state. paquetes", paquetes)
      this.setPaquete(paquetes)

      let nCostoEnvioX = 0.0;
      this.getPaquetes.forEach((element) => {
        nCostoEnvioX = nCostoEnvioX + element.CostoEnvio * element.cantPaq;
      });
      this.preciobase = nCostoEnvioX.toFixed(2);
      this.setCostoEnvio(this.preciobase);
    }
  }
}
