<template>
  <v-container class="ma-1 pa-1">
    <v-snackbar
      v-model="snackbar"
      top
      color="primary"
    >
      {{ msg }}
      <v-btn
        text
        @click.native="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>

    <v-row v-if="cargando">
      <v-col cols="12">
        <v-skeleton-loader
          v-bind="attrs2"
          type="list-item-avatar-three-line, image, article"
        />
      </v-col>
    </v-row>

    <v-row
      v-else
      justify="center"
    >
      <!-- CATEGORIA//LINEA  -->
      <v-col
        cols="12"
        class="py-0"
      >
        <v-card-title
          primary-title
          class="py-0"
        >
          <v-breadcrumbs
            :items="values"
            divider="/"
            class="pa-0"
            @click="goTo(item.to)"
          >
            <template #divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </v-card-title>
      </v-col>
    </v-row>

    <!-- {{ items }} -->
    <v-row justify="center">
      <v-col
        cols="12"
        xl="7"
        lg="7"
        md="7"
        sm="7"
        xs="12"
      >
        <v-card
          v-if="mostrarlogo"
          elevation="10"
        >
          <v-img
            :src="logourl"
            alt="Sin imagen"
          />
        </v-card>

        <v-card elevation="10">
          <v-carousel
            :continuous="false"
            :cycle="false"
            :show-arrows="true"
            hide-delimiter-background
            delimiter-icon="mdi-minus"
          >
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
            >
              <v-sheet tile>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-img
                      contain
                      :lazy-src="item.src"
                      :src="item.src"
                      @click="dialog=true"
                    />

                    <!-- <VueImageZoomer
                      class="center"
                      hover-message="zoom"
                      :regular="item.src"
                      :zoom="item.src"
                      :zoom-amount="3"
                      alt="ver detalle de producto"
                    /> -->


                    <v-dialog
                      v-model="dialog"
                      class="ma-0"
                      transition="dialog-bottom-transition"
                      max-height="2000"
                      max-width="1200"
                    >
                      <!-- <template v-slot:activator="{ on, attrs }">
                        <v-col cols="12">
                          <v-card
                            elevation="10"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-img
                              :src="item.src"
                              contain
                              max-height="auto"
                            />
                          </v-card>
                        </v-col>
                      </template> -->
                        
                      <template #default="dialog">
                        <v-card>
                          <v-toolbar
                            color="primary"
                            dark
                            dense
                          >
                            {{ getArt.numart }} - {{ getArt.details }}
                            <v-spacer />
                            <v-btn
                              text
                              @click="dialog.value = false"
                            >
                              Cerrar
                            </v-btn>
                          </v-toolbar>

                          <v-card-text>
                            <!-- <div class="text-h2 pa-12">Hello world!</div> -->
                            <v-card>
                              <v-img
                                contain
                                :src="item.src"
                                max-height="2000"
                                max-width="1200"
                              />
                            </v-card>
                          </v-card-text>

                          <v-card-actions class="justify-end">
                            <v-btn
                              text
                              @click="dialog.value = false"
                            >
                              Cerrar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-card>

        <v-col
          cols="12"
          xm="12"
        >
          <v-card class="elevation-10">
            <v-system-bar
              dark
              color="secondary"
            >
              Descargar Hojas Técnicas
            </v-system-bar>

            <v-row>
              <v-col
                v-if="getArt.pdfs != ''"
                cols="12"
                md="6"
                xs="12"
              >
                <v-card-title> Hoja técnica pdf {{ pdfslink }} </v-card-title>
                <!-- <Pdf/>    -->
                <a
                  class="mx-4"
                  :href="getArt.pdfslink"
                  target="_blank"
                >
                  {{ getArt.pdfs }}</a>
              </v-col>

              <v-col
                v-if="getArt.pdf2 != ''"
                cols="12"
                md="6"
                xs="12"
              >
                <!-- <pdf2></pdf2> -->

                <v-card-title> Documento pdf </v-card-title>

                <a
                  class="mx-4"
                  :href="getArt.pdf2link"
                  target="_blank"
                >
                  {{ getArt.pdf2 }}</a>
              </v-col>
            </v-row>

            <v-textarea
              v-if="getArt.descripCompleta != ''"
              v-model="getArt.descripCompleta"
              class="mx-1 elevation-0 transparent"
              filled
              auto-grow
              readonly
              rows="4"
              label="Descripción Adicional"
            />
          </v-card>
        </v-col>
      </v-col>

      <v-col
        cols="12"
        xl="5"
        lg="5"
        md="5"
        sm="5"
        xs="12"
      >
        <v-card
          height="100%"
          elevation="10"
        >
          <v-card-title primary-title>
            <h5 class="red--text">
              CODIGO: {{ getArt.numart }}
            </h5>
            <strong><h5>{{ nomart }}</h5></strong>

            <div
              v-if="getArt.pjedesc > 0.0"
              justify="center"
              class="primary--text mx-4 px-2"
            >
              <h3>
                <del><strong>$ {{ precioneto }} </strong></del>
              </h3>
            </div>

            <div
              v-else
              justify="center"
              class="primary--text mx-3"
            >
              <h3>
                <strong>$ {{ precioneto }} </strong>
              </h3>
            </div>

            <div
              v-if="getArt.pjedesc > 0.0"
              justify="center"
              class="red--text pa-1"
            >
              <h3>
                <strong>$ {{ preciobajo }} </strong>
              </h3>
            </div>
          </v-card-title>

          <v-card-text class="ma-0">
            <v-row>
              <v-col
                sm="6"
                xs="12"
                class="ma-0"
              >
                <div
                  v-if="getArt.divisa == 'P'"
                  class="subtitle-2"
                >
                  Precios con iva en pesos
                </div>
                <div
                  v-else
                  class="subtitle-2"
                >
                  Precios con iva en dólares
                </div>

                <!-- EXISTENCIA MAS AGREGAR  -->
                <div>Existencia : {{ existencia }}</div>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                xs="12"
                class="ma-0"
              >
                <v-btn
                  class="ma-2 pa-2"
                  color="primary"
                  dark
                  @click="validar"
                >
                  Agregar
                  <v-icon right>
                    shopping_cart
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-row align="center">
            <!-- BOTON MENOS -->
            <v-col
              cols="1"
              class="pa-2 ma-2"
            >
              <v-btn
                class="mx-3"
                x-small
                fab
                dark
                color="orange "
                @click="remove"
              >
                <v-icon center>
                  remove
                </v-icon>
              </v-btn>
            </v-col>

            <!-- CANTIDAD -->
            <v-col
              cols="4"
              class="pa-1 ma-1 text-center"
            >
              <v-text-field
                v-model.number="cantidad"
                label="Cantidad"
                type="number"
                :value="cantidad"
                min="0"
                class="pa-1 ma-2"
                @change="cantManual"
              />
            </v-col>

            <!-- BOTON MAS -->
            <v-col
              cols="1"
              class="ma-1 pa-1"
            >
              <v-btn
                x-small
                fab
                dark
                color="success"
                @click="add"
              >
                <v-icon center>
                  add
                </v-icon>
              </v-btn>
            </v-col>

            <!-- UNIDAD -->
            <v-col
              cols="4"
              md="4"
              class="pa-2 ma-2"
            >
              <v-select
                v-if="selecionarunidad"
                v-model="select"
                :items="unidades"
                item-text="unidad"
                class="text-right"
                label="Unidad"
                prepend-icon="inbox"
                persistent-hint
                return-object
                @change="PreciosxUnidad"
              />
              <div
                v-else
                class="font-weight-black"
              >
                Unidad: {{ unidad }}
              </div>
            </v-col>

            <v-card-text class="ma-0">
              <!-- <v-subheader>Descripción</v-subheader>             -->
              <!-- OBS -->
              <v-textarea
                v-model="getArt.obs"
                color="secondary"
                label="Descripción del producto"
                class="elevation-0 transparent"
                filled
                rows="2"
                auto-grow
                readonly
              />
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- DATOS ADICONALES -->
    <v-row class="mx-auto">
      <colores />
    </v-row>

    <!-- VIDEO COMPOMENT -->
    <v-row>
      <v-col
        v-if="videoId != ''"
        cols="12"
        md="8"
        xm="12"
      >
        <v-card color="primary">
          <!-- <VideoArt></VideoArt> -->
          <v-row justify="center">
            <v-col
              v-if="videoId != ''"
              cols="12"
            >
              <v-card
                class="elevation-0"
                height="100%"
              >
                <v-card-title primary-title>
                  Video Id: {{ videoId }}
                  <v-btn
                    class="mx-5"
                    color="success"
                    @click="playVideo"
                  >
                    Play
                  </v-btn>
                </v-card-title>

                <!-- v-if="videoId!=''" -->
                <v-card-text align="center">
                  <youtube
                    ref="youtube"
                    height="400"
                    width="80%"
                    :video-id="videoId"
                    @playing="playing"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="4"
        xm="12"
      >
        <v-card elevation-4>
          <v-card-text>
            <v-data-table
              locale="es"
              calculate-widths
              hide-default-footer
              dense
              :headers="headers"
              :items="tablacarac"
              item-key="name"
              class="elevation-1"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- RELACIONADAS -->
    <v-row>
      <!-- <v-col cols="12" class="ma-0 pa-0">
          <v-card class="elevation-0 transparent">
            <v-card-title primary-title>
             Productos Relacionados
            </v-card-title>
          </v-card>
      </v-col>
       -->
      <v-col
        v-for="(articulo, i) in articulos"
        :key="i"
        cols="12"
        xl="2"
        lg="3"
        md="4"
        sm="6"
        xs="12"
        justify="space-around"
      >
        <v-hover>
          <v-card
            slot-scope="{ hover }"
            height="100%"
            class="ma-2"
            :class="`elevation-${hover ? 12 : 2}`"
            @click="info(articulo.Numart)"
          >
            <v-row
              align-start
              justify-center
              row
              wrap
            >
              <v-col
                cols="12"
                xs="12"
                class="elevation-0"
              >
                <v-row>
                  <v-col
                    cols="12"
                    xs="12"
                    class="ma-1 pa-1 text-xs-center text-center"
                  >
                    <v-card-title primary-title>
                      {{ articulo.numart }}
                    </v-card-title>

                    <v-container width="100%">
                      <v-img
                        v-if="articulo.url == ''"
                        src="@/assets/blanco.jpg"
                        alt="Sin imagen"
                        contain
                        max-heigth="200"
                        @click="info(articulo.numart)"
                      />

                      <v-img
                        v-else
                        :src="articulo.url"
                        alt="Sin  imagen"
                        height="250"
                        contain
                        @click="info(articulo.numart)"
                      />
                    </v-container>

                    <v-card-text class="py-0 ma-0">
                      <v-list-item-content class="text--darken-2">
                        <h4>{{ articulo.descrip }}</h4>
                      </v-list-item-content>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>

    <v-btn
      fab
      color="secondary"
      class="white--text mb-10"
      fixed
      bottom
      right
      x-small
      @click="arriba"
    >
      <v-icon>keyboard_arrow_up</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import colores from "@/views/articulos/colores";
// import Pdf from '@/views/articulos/PieArt.vue'
// import Pdf2 from '@/views/articulos/Pdf2.vue'
// import DescripArt from '@/views/articulos/DescripArt.vue'
// import axios from 'axios'

// https://www.npmjs.com/package/vue-image-zoomer
// import imageZoom from "vue-image-zoomer";
var accounting = require("accounting");

// import { VueImageZoomer } from "vue-image-zoomer";
// import "vue-image-zoomer/dist/style.css";

export default {
  components: {
    // Relacionados,
    // Pdf,
    // Pdf2,
    // DescripArt,
    colores,
    // imageZoom,
    // VueImageZoomer,
  },
  metaInfo() {
    return {
      title: this.getArt.numart,
      meta: [
        { vmid: "description", name: "description", content: this.nomart },

        { property: "og:site_name", content: "Grupo Azalie" },
        { property: "og:type", content: "website" },

        { property: "og:image", content: "https://saiterp.com/b1.png" },
        { property: "og:image:type", content: "image/jpeg" },

        { property: "og:image:width", content: "400" },
        { property: "og:image:height", content: "300" },
        { property: "og:image:alt", content: "Mayorista en cosméticos" },

        { name: "robots", content: "index,follow" },
      ],
    };
  },

  data() {
    return {

      dialog: false,
      // title:'Detalle del Producto.',
      preciobajo: 0.0,
      existencia: 0.0,
      articulos: [],
      descrip: [],
      headers: [
        { text: "Tipo", align: "left", sortable: true, value: "name" },
        { text: "Valor", value: "valor", sortable: true },
      ],

      tablacarac: [],

      items: [],
      // cantidad: 1.05,

      detalles: "",
      nomart: "",

      tab: null,
      tabs: [],

      precioutil: "",

      snackbar: false,
      msg: "",
      obs: "",
      unidades: [
        {
          unidad: "",
          preciopub: 0.0,
          precio1: 0.0,
          numart: "",
        },
      ],
      unidad: "",
      cantidad: 1.0,
      selecionarunidad: false,
      select: { numart: "", unidad: "" },

      precioneto: 0.0,
      carrusel: [],
      tokenSait: "",

      hislinea: "",
      values: [],

      facebook: "",
      youtube: "",
      twitter: "",
      linkedin: "",
      attrs2: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
      cargando: true,

      videoId: "",
      video: "",

      logourl: "",
      fotozoom: "",
      mostrarlogo: false,
      pdfslink: "",
      pdf2link: "",
      Envio: {
        cantidad: 1,
        clave: "ENVIO",
        descrip: "ENVIO A DOMICILIO",
        descuento: 0,
        details: "ENVIO A DOMICILIO",
        divisa: "P",
        estatus: "1",
        existencia: "0",
        img: "",
        importe: "1.00",
        impuesto: 0,
        impuesto1: 16,
        iva: 0.0,
        marca: "",
        modelo: "",
        numart: "               ENVIO",
        pjedesc: 0,
        precio: "1.00",
        preciobajo: "1.",
        preciopub: "1.",
        subtotal: 1.0,
        text: "",
        unidad: "SERVI",
        url: "",
      },
    };
  },

  computed: {
    ...mapGetters("tipoemp", ["getTipoemp"]),
    ...mapGetters("config", ["getPrecioutil"]),
    ...mapGetters("tema", ["getLogourl"]),
    ...mapGetters("carrito", ["getTC", "getCarrito"]),
    ...mapGetters("articulos", ["getArt"]),
    ...mapGetters("Login", [
      "getdatosUsuario",
      "getInfoVend",
      "getModo",
      "getClientes",
      "getCliente",
      "getLogeado",
    ]),
    ...mapGetters("calculoEnvios", ["getEnvios"]),

    myCant: function () {
      return this.cantidad;
    },
    player() {
      return this.$refs.youtube.player;
    },
  },

  watch: {
    $route() {
      this.init();
      this.arriba();
    },

    getPrecioutil() {
      this.precioutil = this.getPrecioutil;
    },
  },

  created() {
    this.getArt;
    this.init();
    this.arriba();
  },

  methods: {
    ...mapActions("articulos", ["verArt", "traerArticulosxLinea"]),
    ...mapActions("carrito", ["traerTC", "carritoadd"]),

    playVideo() {
      this.player.playVideo();
    },

    playing() {
      console.log("o/ we are watching!!!");
    },

    getHistLinea(numlin) {
      console.log("getHistLinea",numlin)

      this.values = [];
      this.$http
        .get("api/v1/hislinea/" + numlin)
        .then((response) => {
          // console.log("hislinea",response.data)

          this.hislinea = response.body;

          this.values.push({
            text: this.hislinea.Nomdep,
            disabled: false,
            to: "/categoria/" + this.hislinea.Valdep,
          });

          this.values.push({
            text: this.hislinea.Nomcat,
            disabled: false,
            to: "/lineas/" + this.hislinea.Numcat,
          });

          this.values.push({
            text: this.hislinea.Nomlin,
            disabled: false,
            to: "/artlinea/" + this.hislinea.Numlin,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    goTo(payload) {
      // console.log("goto ", payload);
      this.$router.to(payload);
    },

    PreciosxUnidad() {
      // console.log(this.select.unidad, this.select.preciopub, this.select.precio1)
      this.precioneto = this.select.preciopub;
      this.precio1 = this.select.precio1;
    },

    cantManual(value) {
      this.cantidad = this.cantidad;
    },

    init() {
      // console.log("INIT get art ",this.getArt)
      this.logourl = this.getLogourl;

      this.precioutil = this.getPrecioutil;
      // console.log("init verart", this.precioutil)

      // this.getHistLinea(this.getArt.linea);
      const numart = this.$route.params.numart;

      // VALORES INICIALES
      this.tabs = [];
      this.articulos = [];

      // Validar si es cliente del usuario
      if (this.getCliente != "") {
        this.precioutil = this.getCliente.precioutil;
        // Validar si es cleinte del vendedor
      } else if (this.getClientes != "") {
        this.precioutil = this.getClientes.precioutil;
      }

      this.traerTC()
        .then((response) => {
          const numart = this.$route.params.numart;

          const payload = {
            numart: numart,
            divisa: this.$store.state.tipomoneda,
            tc: this.getTC,
            precioutil: this.precioutil,
          };

          var divisatienda = this.$store.state.tipomoneda;
          var tctienda = this.getTC;

          // console.log("llamando a verArt",payload)

          this.verArt(payload)
            .then((response) => {
              // PROCESO PARA PONER valores en la vista
              this.traerArt();

              // console.log("response verart",response)

              // console.log("VER ART verArt vuex", response)
              var cNumart = response.Numart.trim();
              this.unidad = response.Unidad;
              // console.log(this.unidad)
              this.existencia = response.Existencia;

              // console.log(this.getArt.fotos)
              this.carrusel = [];
              this.carrusel2 = encodeURIComponent(this.getArt.fotos).split(
                "%0D"
              );

              this.carrusel2 = (this.getArt.fotos).split("\r\n")

              var filtered = this.carrusel2.filter(function (el) {
                return el != "";
              });

              this.carrusel = filtered;

              console.log("Carrusel", encodeURIComponent(this.carrusel) )
              console.log("CARRUSEL2", this.carrusel)
              console.log("filtered", filtered)

              // FORMAR UNIDADES
              this.unidades[0].unidad = this.unidad;
              this.unidades[0].precio1 = accounting.formatNumber(
                response.Precio1,
                2
              );
              this.unidades[0].preciopub = accounting.formatNumber(
                response.preciopub,
                2
              );
              this.unidades[0].numart = response.Numart.trim();

              // console.log("getart1",this.getArt.impuesto1)
              this.impuesto1 = this.getArt.Impuesto1;

              const payload = { numart: cNumart };
              this.$http
                .post("api/v1/unidadesxart", payload)
                .then((response) => {
                  // this.$http.post('api/v1/unidadesxart/'+cNumart).then(response => {
                  // console.log("response.data unidadesxart",response.data)

                  if (response.data.total > 0) {
                    this.selecionarunidad = true;
                    // console.log("mostrar unidades",response.data.Rows)

                    response.data.Rows.forEach((element) => {
                      // console.log("element",element)
                      var valorInicial = element.precio1;

                      // getClientes.precioutil
                      switch (this.getClientes.precioutil) {
                      case "1":
                        element.precio1 = element.precio1;
                        break;

                      case "2":
                        element.precio1 = element.precio2;
                        break;

                      case "3":
                        element.precio1 = element.precio3;
                        break;

                      case "4":
                        element.precio1 = element.precio4;
                        break;

                      case "5":
                        element.precio1 = element.precio5;
                        break;

                      default:
                        element.precio1 = element.precio1;
                        break;
                      }

                      if (element.precio1 == "") {
                        element.precio1 = valorInicial;
                      }

                      var impuesto = 1;
                      if (this.getArt.impuesto1 > 0) {
                        impuesto = 1 + this.getArt.impuesto1 / 100;
                      }

                      if (this.getArt.divisaart == "D" && divisatienda == "P") {
                        element.preciopub =
                          parseFloat(element.precio1) * impuesto * tctienda;
                        element.precio1 =
                          parseFloat(element.precio1) * tctienda;
                      } else if (
                        this.getArt.divisaart == "P" &&
                        divisatienda == "D"
                      ) {
                        element.preciopub =
                          (parseFloat(element.precio1) * impuesto) / tctienda;
                        element.precio1 =
                          parseFloat(element.precio1) / tctienda;
                      } else {
                        // MISMA DIVISA
                        element.preciopub =
                          parseFloat(element.precio1) * impuesto;
                      }

                      var descuento = 0;
                      if (this.getArt.Pjedesc > 0) {
                        descuento =
                          accounting.unformat(this.getArt.preciopub) *
                          (this.getArt.Pjedesc / 100);
                      }

                      // console.log(element)
                      this.unidades.push({
                        unidad: element.unidad,
                        precio1: element.precio1,
                        preciopub: accounting.formatNumber(
                          element.preciopub,
                          2
                        ),
                        numart: element.numart,
                      });
                    });
                    // console.log("UNIDADES",this.unidades)
                  }
                })
                .catch((err) => {
                  console.log(err);
                });

              this.select.unidad = this.unidad;
              this.select.numart = this.numart;

              var LineaSel = { numlin: response.Linea };
              this.articulos = [];

              // this.traerArticulosxLinea(LineaSel).then(respuesta => {
              //   // console.log("RELACIONADOS",respuesta)
              //   // console.log (respuesta[0].numart)
              //    respuesta.forEach(element => {
              //     element.Preciopub = accounting.formatNumber(element.Preciopub, 2)
              //     this.articulos.push(element)
              //   })
              //   // console.log(this.articulos)
              // }).catch(err => {console.log(err)})
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    seluni() {
      console.log(this.unidad);
    },

    // ver art
    info(numart) {
      var NumArt = numart.trim();
      this.$router
        .push({ name: "verart", params: { numart: NumArt } })
        .catch((err) => {});
    },

    arriba() {
      window.scrollTo(500, 0);
    },

    // SUMA UN PRODUCTO más
    add() {
      // console.log("add",this.cantidad)

      if (this.getModo == "vendedor") {
        if (this.$store.state.Login.cliente == "") {
          this.msg =
            "Selecciona un cliente antes de agregar cualquier articulo";
          this.snackbar = true;
        } else {
          this.cantidad = this.cantidad + 1.0;
        }
      } else {
        this.cantidad = this.cantidad + 1.0;
      }
    },

    // RESTA un producto menos.
    remove() {
      // console.log("remove",this.cantidad)

      if (this.getModo == "vendedor") {
        // MODO VENDEDOR
        if (this.$store.state.Login.cliente == "") {
          this.msg =
            "Selecciona un cliente antes de agregar cualquier articulo";
          this.snackbar = true;
        } else {
          if (this.cantidad <= 1.0) {
            this.cantidad = 1.0;
          } else {
            this.cantidad = this.cantidad - 1.0;
          }
        }
      } else {
        // MODO NORMAL
        if (this.cantidad <= 1.0) {
          this.cantidad = 1.0;
        } else {
          this.cantidad = this.cantidad - 1.0;
        }
      }
    },

    // CUAL ES OBJETIVO DE ESTE METODO? ???
    traerArt() {
      var url = this.$http.options.root.replace("tienda3", "fotossait/");
      console.log("this.getArt", this.getArt)
      this.nomart = this.getArt.details;
      this.items = [];
      this.obs = this.getArt.obs;
      // PARA CAMBIO DE Und
      this.precioneto = this.getArt.preciopub;
      this.precio1 = this.getArt.Precio1;

      // calculo de precio bajo
      var nPrecioPub = accounting.unformat(this.getArt.preciopub);
      var nDescuento = this.getArt.pjedesc;
      var nPreciobajo = nPrecioPub * (1 - nDescuento / 100);
      this.preciobajo = accounting.formatNumber(nPreciobajo, 2);

      if (this.getArt.img == "") {
        // this.items.push({src: ''})
        this.mostrarlogo = true;
      } else {
        this.items.push({ src: this.getArt.img });
        this.fotozoom = this.getArt.img;
      }

      //MANDAR CARGAR LINEA
      this.getHistLinea(this.getArt.linea);

      this.tablacarac = [
        { name: "Clave", valor: this.getArt.numart },
        { name: "Unidad", valor: this.getArt.unidad },
        { name: "Altura (CM)", valor: this.getArt.altura },
        { name: "Ancho (CM)", valor: this.getArt.ancho },
        { name: "Largo (CM)", valor: this.getArt.largo },

        { name: "Peso (KG)", valor: this.getArt.peso },

        // {name: 'Tamaño (cm)', valor: this.getArt.altura + ' x ' +this.getArt.ancho + ' x ' + this.getArt.largo },
        { name: "Marca", valor: this.getArt.marca },
        { name: "Modelo", valor: this.getArt.modelo },
        { name: "Línea", valor: this.getArt.linea },

        { name: "Categoría", valor: this.getArt.categoria },
        { name: "Youtube Link", valor: this.getArt.youtube_link },
      ];

      // BBJa32lCaaY
      // this.videoId = this.getArt.video.substr(-11)
      this.videoId = this.getArt.video.substr(-11);
      // console.log("VIDEO ID ",this.videoId)

      // console.log("carrusel3", this.carrusel)
      // if(this.getArt.fotosarr != null){
      //   for (var i = this.getArt.fotosarr.length - 1; i >= 0; i--) {
      //     this.items.push({src:url + this.getArt.fotosarr[i].Image_name})
      //   }
      // }

      // Expresión para poner todo el texto y ver los cambios de linea.
      // this.carrusel2 = encodeURIComponent(this.getArt.fotos).split("%0D");
      this.carrusel2 = (this.getArt.fotos).split("\r\n")


      var filtered = this.carrusel2.filter(function (el) {
        return el != "";
      });

      this.carrusel = filtered;
      // this.items = filtered
      // console.log("CARRUSEL", this.carrusel)
      this.carrusel.forEach((element) => {
        if (element != "") {
          this.items.push({ src: url + element });
        }
      });

      // DETALLES ?? por que no se llaman las cosas por su nombre.
      this.detalles = this.getArt.obs;

      // TERMINO DE CARGAR
      this.cargando = false;
    },

    validar() {
      if (this.getModo == "vendedor") {
        if (this.getCliente == "") {
          this.msg =
            "Selecciona un cliente antes de agregar cualquier articulo";
          this.snackbar = true;
        } else {
          this.agregar();
        }
      } else {
        this.agregar();
      }
    },

    // Agregar producto al carrito
    agregar() {

      this.getArt.cantidad = this.myCant;

      if (this.getCarrito.length == 0) {
        this.getArt.cantidad = this.myCant;
      } else {
        for (var i = this.getCarrito.length - 1; i >= 0; i--) {
          if (this.getArt.clave == this.getCarrito[i].clave) {
            // console.log("cantidad", this.cantidad)
            this.getArt.cantidad = this.getCarrito[i].cantidad + this.cantidad;
          }
        }
      }

      var nUndAdic = this.unidades.length;
      // console.log(nUndAdic)
      if (nUndAdic > 1) {
        // console.log("UNIDAD ADD", this.select.unidad, this.select.precio1)
        // this.getArt.unidad = this.select.unidad
        this.getArt.preciopub = this.precioneto;
        this.getArt.precio = this.precio1;
        this.getArt.precio1 = this.precio1;
        this.getArt.unidad = this.select.unidad;
      }

      // Agrega la partida al vuex
      // console.log(this.getArt.cantidad, this.getArt.unidad)
      this.carritoadd(this.getArt).then((response) => {
        this.$store.state.carrito.carritovisible = true;
      });

      // AGREGAR COSTO DEL ENVIO
      let enviopar = {
        cantidad: 1,
        clave: "ENVIO",
        descrip: "ENVIO A DOMICILIO",
        descuento: 0,
        details: "ENVIO A DOMICILIO",
        divisa: "P",
        estatus: "1",
        existencia: "0",
        img: "",
        importe: "1.00",
        impuesto: 0,
        impuesto1: 16,
        iva: 0.0,
        marca: "",
        modelo: "",
        numart: "               ENVIO",
        pjedesc: 0,
        precio: 1,
        preciobajo: "1.00",
        preciopub: "1.00",
        subtotal: 1.0,
        text: "",
        unidad: "SERVI",
        url: "",
        tipoemp: "F",
      };

      // Agregar envio.
      // CASE MIKAI tipoCalEnv = 1
      const mikai = false;
      if (mikai === true) {
        var nTotal = accounting.unformat(this.total);
        this.preciobase = 0.0;
        this.getEnvios.forEach((element) => {
          // console.log(element.Monto)
          if (nTotal >= element.Monto) {
            // console.log("get.envios", element.Preciobase)
            this.preciobase = element.Preciobase;
          }
        });

        enviopar.cantidad = 1;
        enviopar.precio = this.preciobase;
        // console.log("mikai");
        // console.log(this.enviopar);
        this.carritoadd(this.enviopar);
      }

      // CASE REDLEAF
      const redleaf = true;
      if (redleaf == true) {
        let articulo = this.getArt
        console.log('articulo', articulo)
        
        // this.setultnumart(articulo);

        // SI NO EXISTE TIPOEMP... Se crea como Tipo F. No requiere envio.
        if (articulo.tipoemp === undefined) {
          articulo.tipoemp = "F";
        }

        console.log("getTipoEmp", articulo.tipoemp);
        // let CostoEnvio = 0.0;
        // console.log("articulo", articulo);

        this.getTipoemp.forEach((element) => {
          if (element.tipoemp == articulo.tipoemp) {
            // CostoEnvio = element.costoenv;
            // console.log("element.tipoemp", element.costoenv);
            enviopar.precio = element.costoenv.toString();
            enviopar.cantidad = 1;
          }
        });
        this.carritoadd(enviopar);
      }
      // Agregar envio.
      // var nTotal = accounting.unformat(this.total);
      // this.preciobase = 0.0;
      // this.getEnvios.forEach((element) => {
      //   // console.log(element.Monto)
      //   if (nTotal >= element.Monto) {
      //     console.log("get.envios", element.Preciobase);
      //     this.preciobase = element.Preciobase;
      //   }
      // });
      // this.Envio.cantidad = 1;
      // this.Envio.precio = this.preciobase;
      // this.carritoadd(this.Envio);



    },
  },
};
</script>
<style>
img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
</style>
