<template>
  <v-container class="ma-1">
    <v-snackbar
      v-model="snackbar"
      top
      color="secondary"
    >
      {{ msg }}
      <v-btn
        text
        @click.native="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-card color="primary">
      <v-row justify="center">
        <v-col
          class="ma-2"
          cols="12"
          xl="3"
          md="3"
          sm="6"
          xs="12"
        >
          <v-card
            class="elevation-5"
            align="justify"
          >
            <v-card-title primary-title>
              {{ getCIA.nomcia }}
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-textarea
                v-model="nosotros"
                height="100%"
                auto-grow
                readonly
              />
            </v-card-text>

            <v-card-actions class="ma-2 pt-2">
              <a
                v-if="facebook"
                :href="facebook"
                target="_blank"
                class="mr-3"
              >
                <img
                  src="@/assets/Facebook.png"
                  width="100%"
                >
              </a>
              <a
                v-if="youtube"
                :href="youtube"
                target="_blank"
                class="icon-pinterest mr-3"
              >
                <img
                  src="@/assets/Youtube.png"
                  width="100%"
                >
              </a>
              <a
                v-if="twitter"
                :href="twitter"
                target="_blank"
                class="icon-twitter mr-3"
              >
                <img
                  src="@/assets/Twitter.png"
                  width="100%"
                >
              </a>
              <a
                v-if="linkedin"
                :href="linkedin"
                target="_blank"
                class="icon-googleplus mr-3"
              >
                <img
                  src="@/assets/In.png"
                  width="100%"
                >
              </a>

              <a
                v-if="whatsapp"
                :href="whatsapp"
                target="_blank"
                class="mr-3"
              >
                <img
                  src="@/assets/whatsapp.png"
                  width="100%"
                >
              </a>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col
          class="ma-2"
          cols="12"
          xl="4"
          md="4"
          sm="6"
          xs="12"
        >
          <v-card
            class="elevation-4"
            align="justify"
          >
            <v-card-title primary-title>
              Contáctanos
              <v-spacer />
              <v-btn
                color="secondary"
                @click="validar"
              >
                Enviar
              </v-btn>
            </v-card-title>
            <v-divider dark />

            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  class="pa-2"
                >
                  <v-text-field
                    v-model="Nombre"
                    label="Nombre"
                  />

                  <v-text-field
                    v-model="Email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                  />

                  <v-text-field
                    v-model="Telefono"
                    label="Teléfono"
                  />

                  <v-text-field
                    v-model="Empresa"
                    label="Empresa"
                  />

                  <v-textarea
                    v-model="Mensaje"
                    label="Mensaje"
                    auto-grow
                    outlined
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          class="ma-2"
          cols="12"
          xl="4"
          md="4"
          sm="6"
          xs="12"
        >
          <v-card class="elevation-2">
            <v-card-text>
              <div id="map" />
            </v-card-text>
            <v-divider />
          </v-card>

          <v-card>
            <v-card-text class="ma-2">
              <div class="text-xs-left">
                <strong>{{ getCIA.nomcia }}</strong>
              </div>
              <div class="text-xs-left">
                <strong>{{ getCIA.rfccia }}</strong>
              </div>

              {{
                getCIA.calle +
                  ", " +
                  getCIA.numext +
                  ", " +
                  getCIA.colonia +
                  ", " +
                  getCIA.ciudad +
                  ", " +
                  getCIA.estado +
                  ", " +
                  getCIA.cp
              }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col
          class="ma-2"
          cols="12"
          xl="3"
          md="3"
          sm="6"
          xs="12"
        >
          <v-card :to="{ path: '/polenvio' }">
            <div class="mx-4 text-xs">
              <strong> Política de envío.</strong>
            </div>
          </v-card>
        </v-col>

        <v-col
          class="ma-2"
          cols="12"
          xl="4"
          md="4"
          sm="6"
          xs="12"
        >
          <v-card :to="{ path: '/terminos' }">
            <div class="mx-4 text-xs">
              <strong> Términos y Condiciones</strong>
            </div>
          </v-card>
        </v-col>

        <v-col
          class="ma-2"
          cols="12"
          xl="4"
          md="4"
          sm="6"
          xs="12"
        >
          <v-card :to="{ path: '/aviso' }">
            <div class="mx-4 text-xs">
              <strong>Aviso de Privacidad</strong>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      facebook: "",
      youtube: "",
      twitter: "",
      linkedin: "",
      whatsapp: "",

      latitude: 25.795222879371323,
      longitude: -100.3072329946799,

      logo: "",
      email1: "",
      email2: "",
      primario: "",
      secundario: "",

      nosotros: "",

      Nombre: "",
      Telefono: "",
      Email: "",
      Empresa: "",
      Mensaje: "",
      fecha: new Date().toISOString().substr(0, 10),

      snackbar: false,
      msg: "",

      emailRules: [
        (v) => !!v || "E-mail es requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail no es válido.",
      ],
    };
  },

  computed: {
    ...mapGetters("Login", [
      "getdatosUsuario",
      "getClientes",
      "getCliente",
      "getCIA",
      "getInfoVend",
      "getModo",
    ]),
    ...mapGetters("Login", ["getCIA"]),
  },

  mounted() {
    this.initializeMap();
  },
  
  created() {
    this.init();
  },


  methods: {
    init() {
      this.$http
        .get("api/v1/redessoc")
        .then((respuesta) => {
          // console.log("respuesta", respuesta)
          this.facebook = respuesta.body.Facebook;
          this.youtube = respuesta.body.Youtube;
          this.twitter = respuesta.body.Twitter;
          this.linkedin = respuesta.body.Linkedin;
          this.whatsapp = respuesta.body.Whatsapp;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$http
        .get("api/v1/nosotros.list")
        .then((response) => {
          this.nosotros = response.body.Nosotros;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$http.get("api/v1/logos.list").then(
        (response) => {
          this.logo = response.body.Logourl;
        },
        (error) => {
          console.log(error);
        }
      );

      this.$http
        .get("api/v1/email.list")
        .then((response) => {
          this.email1 = response.body.Email1;
          this.email2 = response.body.Email2;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$http
        .get("api/v1/tema.list/" + 2)
        .then((respuesta) => {
          // console.log(respuesta)
          this.primario = respuesta.body.primario;
          this.secundario = respuesta.body.secundario;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Validamos que todos los campos esten llenos
    validar() {
      if (
        this.Nombre != "" &&
        this.Telefono != "" &&
        this.Email != "" &&
        this.Empresa != "" &&
        this.Mensaje != ""
      ) {
        // Llamamos la función de agregar el prospecto "addProspecto"
        this.addProspecto();
      } else {
        // Mostramos un mensaje para decir que fatan campos para llenar
        this.msg = "Completa los campos";
        this.snackbar = true;
      }
    },

    addProspecto() {
      var payload = {
        Nombre: this.Nombre,
        Telefono: this.Telefono,
        Correo: this.Email,
        Empresa: this.Empresa,
        Comentarios: this.Mensaje,
        Hora: this.hora(),
        Fecha: this.fecha,
      };

      // console.log("payload", payload)
      this.$http
        .post("api/v1/prospectos", payload)
        .then((response) => {
          this.sendEmail();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    sendEmail() {
      var payloadEmail = {
        Nombre: this.Nombre,
        Email: this.Email,
        Cprimario: this.primario,
        Csecundario: this.secundario,
        Logo: this.logo,
        nomempresa: this.getCIA.nomcia,
        Email1: this.email1,
        Email2: this.email2,
      };

      console.log("sendEmail", payloadEmail);
      this.$http
        .post("api/v1/sendprospecto", payloadEmail)
        .then((response) => {
          this.msg = "Correo enviado";
          this.snackbar = true;
          this.limpiar();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    limpiar() {
      this.Nombre = "";
      this.Telefono = "";
      this.Email = "";
      this.Empresa = "";
      this.Mensaje = "";
    },

    initializeMap() {
      this.$http
        .get("api/v1/getcoords")
        .then((response) => {
          // console.log(response.body)
          if (this.latitude !== "") {
            this.latitude = parseFloat(response.body.Lat);
            this.longitude = parseFloat(response.body.Lng);
            var center2 = { lat: this.latitude, lng: this.longitude };
          } else {
            var center2 = { lat: this.latitude, lng: this.longitude };
          }

          this.optionsMap = {
            center: center2,
            zoom: 15,
            maxZoom: 15,
            minZoom: 3,
            streetViewControl: true,
          };

          // CREAR MAPA
          const map = new google.maps.Map(
            document.getElementById("map"),
            this.optionsMap
          );
          // Poner Marcador.
          const marker = new google.maps.Marker({
            position: {
              lat: this.latitude,
              lng: this.longitude,
              title: "Localización",
            },
            map,
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },

    hora() {
      var f = new Date();
      var hora = f.getHours();
      var minuto = f.getMinutes();
      var segundo = f.getSeconds();

      if (hora < 10) {
        hora = "0" + f.getHours();
      }
      if (minuto < 10) {
        minuto = "0" + f.getMinutes();
      }
      if (segundo < 10) {
        segundo = "0" + f.getSeconds();
      }

      return hora + ":" + minuto + ":" + segundo;
    },
  },
};
</script>
<style scoped>
#map {
  height: 350px;
  width: 100%;
  margin: 1px;
}

.social ul {
  list-style: none;
}
</style>
