<template>
  <v-container
    fill-height
    grid-list-xl
    class="ma-0"
  >
    <v-row
      align="start"
      fill-height
    >
      <v-col
        v-if="getCarrito.length === 0"
        cols="12"
        xs="12"
      >
        <v-alert type="info">
          Tu carrito esta vacio.
        </v-alert>
      </v-col>

      <v-col
        v-else
        cols="12"
        xs="12"
        class="ma-1 pa-1"
      >
        <v-card class="elevation-5">
          <v-select
            v-if="mostrarDirenvio"
            v-model="select"
            class="mt-4"
            :hint="`  ${select.Calle} ${select.Numext} ${select.colonia} ${select.Ciudad}
        ${select.Estado} CP ${select.Cp} Tel. ${select.telefono} `"
            :items="direccionescaja"
            item-text="nombre"
            item-value="idweb"
            label="Dirección de envio"
            prepend-icon="house"
            persistent-hint
            return-object
            @change="cambiodirenvio"
          />

          <!-- COSTOENVIO	{{preciobase}} -->
          <v-row
            justify="center"
            class="ma-0"
          >
            <v-col
              cols="4"
              xs="4"
              class="ma-0 pa-0"
            >
              <v-card class="elevation-0">
                <v-card-text class="pr-0 pl-1">
                  <div class="text-xs-left">
                    <h3><strong>Divisa:</strong></h3>
                  </div>
                  <v-divider />

                  <div class="text-xs-left blue--text font-weight-black">
                    Importe:
                  </div>
                  <div class="text-xs-left blue--text font-weight-black">
                    Descuento:
                  </div>

                  <div
                    v-if="mostrarPreciosNetos"
                    class="text-xs-left"
                  >
                    <h3>Subtotal:</h3>
                  </div>
                  <div
                    v-if="mostrarPreciosNetos"
                    class="text-xs-left font-weight-black"
                  >
                    <h3>Iva:</h3>
                  </div>
                  <div class="text-xs-left font-weight-black">
                    <h3>Total:</h3>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="5"
              xs="6"
              class="ma-0 pa-0"
            >
              <v-card class="elevation-0">
                <v-card-text
                  align="right"
                  class="pr-1 pl-1"
                >
                  <div
                    v-if="$store.state.tipomoneda == 'P'"
                    class="text-xs-left"
                  >
                    <h3>MXN</h3>
                  </div>
                  <div
                    v-else
                    class="text-xs-left font-weight-black"
                  >
                    <h3>USD</h3>
                  </div>

                  <v-divider />
                  <div class="text-xs-left blue--text font-weight-black">
                    <h3>{{ importedoc }}</h3>
                  </div>

                  <div class="text-xs-left blue--text font-weight-black">
                    <h3>{{ descuentodoc }}</h3>
                  </div>

                  <div
                    v-if="mostrarPreciosNetos"
                    class="text-xs-left"
                  >
                    <h3>
                      <strong>{{ subtotal }}</strong>
                    </h3>
                  </div>
                  <div
                    v-if="mostrarPreciosNetos"
                    class="text-xs-left"
                  >
                    <h3>
                      <strong>{{ iva }}</strong>
                    </h3>
                  </div>
                  <div class="text-xs-left">
                    <h3>
                      <strong>{{ total }}</strong>
                    </h3>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row
            class="mb-2"
            justify="space-around"
          >
            <v-btn
              small
              color="red darken-2"
              dark
              @click="iracarrito"
            >
              Pagar
              <v-icon right>
                shopping_cart
              </v-icon>
            </v-btn>

            <v-btn
              small
              color="blue darken-4"
              dark
              @click="ocultar"
            >
              <v-icon right>
                reply
              </v-icon>
            </v-btn>
          </v-row>

          <v-divider />
        </v-card>

        <!-- CARRITO -->
        <v-card
          v-for="(art, i) in getCarrito"
          :key="i"
          class="elevation-10 ma-1"
        >
          <v-container
            fill-height
            grid-list-xl
            class="pa-1"
          >
            <v-row>
              <v-col
                xs="3"
                class="mr-0 pr-0"
              >
                <v-card-text
                  align="center"
                  class="mb-0 pb-0 pa-0 pt-3"
                >
                  <v-img
                    v-if="art.img == ''"
                    contain
                    :src="getLogourl"
                    width="60"
                  />
                  <v-img
                    :src="art.img"
                    contain
                    width="60"
                  />

                  <v-btn
                    x-small
                    color="success"
                  >
                    {{ art.tipoemp }}
                  </v-btn>
                </v-card-text>
              </v-col>

              <v-col
                xs="3"
                class="mr-0 pr-0 px-0"
              >
                <v-card-text
                  align="left"
                  class="mb-0 pb-0 px-0"
                >
                  <h5>{{ art.details.substr(0, 30) }}</h5>
                </v-card-text>
              </v-col>

              <v-col
                xs="6"
                text-right
                class="mr-0 pl-0"
              >
                <v-card-actions>
                  <v-btn
                    v-if="art.clave != 'ENVIO'"
                    small
                    icon
                    fab
                    color="success"
                    @click="add(art)"
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                  <v-spacer />
                  <h5>{{ art.cantidad }}</h5>

                  <v-spacer />
                  <v-btn
                    v-if="art.clave != 'ENVIO'"
                    small
                    icon
                    fab
                    dark
                    color="orange "
                    @click="remove(art)"
                  >
                    <v-icon>remove</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-card-text class="pt-1 pa-0">
                  <h5
                    v-if="mostrarPreciosNetos"
                    class="text-xs-center blue--text font-weight-black"
                  >
                    Precio: {{ art.precio }}
                  </h5>
                  <h5>Importe: {{ art.total }}</h5>
                  <h5>Unidad: {{ art.unidad }}</h5>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
          <v-divider />
        </v-card>

        <!-- OPCIONES DEL CARRITO -->
        <v-card class="elevation-10 ma-1">
          <v-col xs="12">
            <v-img
              contain
              :src="getLogourl"
            />

            <v-row
              justify="space-around"
              class="ma-2"
            >
              <v-btn
                small
                color="info"
                dark
                @click="irhome"
              >
                Home
                <v-icon right>
                  shopping_cart
                </v-icon>
              </v-btn>

              <v-btn
                small
                color="error"
                dark
                @click="limpiar"
              >
                Limpiar
                <v-icon right>
                  reply
                </v-icon>
              </v-btn>

              <v-card
                elevation="5"
                dense
                class="mt-4 mx-auto"
                tile
              >
                <v-simple-table
                  v-if="verTablaEnv"
                  dense
                >
                  <template #default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Tipo Emp.
                        </th>
                        <th class="text-left">
                          Costo Envio
                        </th>
                        <th class="text-left">
                          Actual
                        </th>
                        <th class="text-left">
                          Paq.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in getPaquetes"
                        :key="item.name"
                      >
                        <td>{{ item.tipoemp }}</td>
                        <td>{{ item.CostoEnvio }}</td>
                        <td>{{ item.cantActTipoEmp }}</td>
                        <td>{{ item.cantPaq }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-row>

            <v-btn
              v-if="mostrarDirenvio"
              dark
              block
              color="blue"
              @click="skydrop"
            >
              Re calcular Skydrop
            </v-btn>
            <v-card
              v-if="mostrarDirenvio"
              elevation="5"
              dense
              class="mt-4 mx-auto"
              tile
            >
              <v-simple-table
                v-if="verTablaEnv"
                dense
              >
                <template #default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Provedor
                      </th>
                      <th class="text-left">
                        precio
                      </th>
                      <th class="text-left">
                        Dias
                      </th>
                      <th class="text-left">
                        servicio
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in tarifasky"
                      :key="item.name"
                    >
                      <td>{{ item.provider }}</td>
                      <td>{{ item.amount_local }}</td>
                      <td>{{ item.days }}</td>
                      <td>{{ item.service_level_code }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import tempCarrito from "@/mixins/tempCarrito.js";
import { mapActions, mapGetters, mapState } from "vuex";
var accounting = require("accounting");

export default {
  mixins: [tempCarrito],
  data() {
    return {
      verTablaEnv: true,
      logourl: "",
      importedoc: 0.0,
      descuentodoc: 0.0,
      total: 0,
      iva: 0,
      subtotal: 0,
      mostrarPreciosNetos: false,
      preciobase: 0.0,
      // paquetes:[],
      AddCantidad: false,
      removeCant: false,
      Envio: {
        cantidad: 1,
        clave: "ENVIO",
        descrip: "ENVIO A DOMICILIO",
        descuento: 0,
        details: "ENVIO A DOMICILIO",
        divisa: "P",
        estatus: "1",
        existencia: "0",
        img: "",
        importe: "1.00",
        impuesto: 0,
        impuesto1: 16,
        iva: 0.0,
        marca: "",
        modelo: "",
        numart: "               ENVIO",
        pjedesc: 0,
        precio: "1.00",
        preciobajo: "1.",
        preciopub: "1.",
        subtotal: 1.0,
        text: "",
        unidad: "SERVI",
        url: "",
      },
      select: {
        nombre: "",
        idweb: "",
        encargado: "",
        Calle: "",
        Numext: "",
        colonia: "",
        Ciudad: "",
        Estado: "",
        Cp: "",
        telefono: "",
        numclisuc: "",
      },
      direccionescaja: [],
      mostrarDirenvio: false,
      tarifasky: [],
    };
  },

  computed: {
    ...mapGetters("carrito", ["getCarrito", "getUltNumart", "getPaquetes"]),
    ...mapState("carrito", ["carrito"]),
    ...mapGetters("tema", ["getLogourl"]),
    ...mapGetters("config", ["getConfig"]),
    ...mapGetters("calculoEnvios", ["getEnvios"]),
    ...mapGetters("tipoemp", ["getTipoemp"]),
    ...mapGetters("Login", ["getdatosUsuario", "getClientes"]),
    ...mapGetters("direnvio", ["getDirecciones", "getDirenvio"]),
  },

  watch: {
    getdatosUsuario() {
      // this.getNumclisuc
      console.log("caja, cambio usuario", this.getdatosUsuario);
      console.log("getDireccones", this.getDirecciones);

      // if (this.getdatosUsuario.email != undefined){
      //   this.mostrarDirenvio = false
      // } else {
      //   this.select = this.getDirenvio
      //   console.log("getDireccones", this.getDirecciones)
      //   this.direccionescaja = this.getDirecciones
      //   this.mostrarDirenvio = true
      // }
    },
    getCarrito() {
      this.recalcular();
      console.log("getdatosUsuairo", this.getdatosUsuario);
    },

    getConfig() {
      if (this.getConfig.preciosnetos == "1") {
        this.mostrarPreciosNetos = false;
      }
      if (this.getConfig.preciosnetos == "0") {
        this.mostrarPreciosNetos = true;
      }
    },
  },

  created() {
    // console.log("getLogourl", this.getLogourl)
    this.logourl = this.getLogourl;

    // this.getDirecciones
    // this.getDirenvio
    this.select = this.getDirenvio;
    this.direccionescaja = this.getDirecciones;

    this.$store.watch((state, getters) => state.tipomoneda);
    this.recalcular;
  },

  methods: {
    ...mapActions("carrito", [
      "carritoadd",
      "limpiarCarrito",
      "setCostoEnvio",
      "setultnumart",
      "setPaquetes",
      "setPaquete",
      "delPaquete",
    ]),
    ...mapActions("direnvio", ["setDirenvio"]),

    ocultar() {
      this.$store.state.carrito.carritovisible = false;
    },

    cambiodirenvio() {
      console.log("cambiodireccion", this.select);
      this.setDirenvio(this.select);
      // this.direccionescaja = this.getDirecciones
    },

    skydrop() {
      console.log(this.getPaquetes);
      console.log(this.getTipoemp);
      //Recorrer paquetes para solo consultar lo que lleva.
      this.getPaquetes.forEach((element) => {
        //REcorrer tipo emp para calcular
        this.getTipoemp.forEach((tipoemp) => {
          if (tipoemp.tipoemp === element.tipoemp && element.tipoemp != "F") {
            // console.log(element)
            let payload = {
              zip_from: "64460",
              zip_to: this.select.Cp,
              parcel: {
                weight: tipoemp.peso.toString(),
                height: tipoemp.alto.toString(),
                width: tipoemp.ancho.toString(),
                length: tipoemp.largo.toString(),
              },
              // carriers: [{ "name": "DHL" }, { "name": "Fedex" }]
            };

            console.log("payload n", payload);
            // var url = process.env.VUE_APP_SAIT_NUBE
            var uri = "https://api-demo.skydropx.com/v1/quotations";

            let tokenSkydrop =
              "Token token=BUKdb9ZGMt8rcyIR6du7h17UABuTQu4n8DyMY8VyL3kt";
            // console.log(uri)

            axios
              .post(uri, payload, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: tokenSkydrop,
                },
              })
              .then((response) => {
                console.log("sky Drop response", response.data);
                this.tarifasky = response.data;

              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      });
    },

    recalcular() {
      console.log(
        "created recalcular",
        this.getCarrito
      );

      this.formarCarrito();

      console.log(" getdatosUsuario ", this.getdatosUsuario);
      console.log("this.getCliente", this.getClientes.numcli);
      if (this.getClientes.numcli === undefined) {
        this.mostrarDirenvio = false;
      } else {
        this.select = this.getDirenvio;
        console.log("getDireccones", this.getDirecciones);
        this.direccionescaja = this.getDirecciones;

        this.mostrarDirenvio = true;
      }

      const mikai = false;
      if (mikai === true) {
        var nTotal = accounting.unformat(this.total);
        this.preciobase = 0.0;
        this.getEnvios.forEach((element) => {
          if (nTotal >= element.Monto) {
            // console.log("get.envios", element.Preciobase)
            this.preciobase = element.Preciobase;
          }
        });
      }

      const azalie = false;
      if (azalie == true) {
        return;
      }

      // CASE REDLEAF
      const redleaf = true;
      if (redleaf == true) {
        // SI NO EXISTE TIPOEMP... Se crea como Tipo F. No requiere envio.
        // if (typeof (articulo.tipoemp)== undefined){
        //   articulo.tipoemp = 'F'
        // }
        // console.table(this.getTipoemp)
        let CostoEnvio = 0.0;
        let nCantMaxTipEmp = 0.0;
        // console.log("getUltNumart", this.getUltNumart);
        // // console.table("getTipoemp", this.getTipoemp);
        // console.log("getPaquetes", this.getPaquetes);

        // console.log("remove cant", this.removeCant)
        this.getTipoemp.forEach((element) => {
          if (element.tipoemp == this.getUltNumart.tipoemp) {
            // console.log(element)
            CostoEnvio = element.costoenv;
            nCantMaxTipEmp = element.cantmax;

            // console.log("nCantMaxTipEmp",nCantMaxTipEmp)
          }
        });
        // console.log("CostoEnvio", CostoEnvio)
        // this.Envio.cantidad = 2
        // this.Envio.precio = CostoEnvio
        this.preciobase = CostoEnvio;

        // CALCULO ACUMULATIVO DE ENVIOS POR TIPO DE PAQUETE.
        // let paquetes = []
        // console.log("carrito",this.getCarrito)
        // 1. Si la tabla de paquetes está vacia. Se agrega.
        // 2. Si la tabla de paquetes existe se recorre.
        // 3. Se suman para calcular el costoEnvio

        // console.table(this.getPaquetes)
        // console.log('paquetes', this.getPaquetes)
        if (this.getPaquetes.length == 0) {
          // console.log('nuevo')
          // console.log("removecant", this.removeCant)
          // agrega un empaque nuevo, cuando se borra.

          if (this.removeCant == false) {
            console.log("Caso 1. Nuevo producto en el arreglo");
            // this.paquetes.push({'CostoEnvio':CostoEnvio, tipoemp:this.getUltNumart.tipoemp})
            this.setPaquetes({
              CostoEnvio: CostoEnvio,
              tipoemp: this.getUltNumart.tipoemp,
              cantActTipoEmp: 1,
              cantMax: nCantMaxTipEmp,
              cantPaq: 1,
            });
          }
        }

        if (this.getPaquetes.length > 0) {
          // Buscar el tipoemp en paquetes
          // encontrar this.getUltNumart.tipoemp dentro de arreglo de paquetes.
          // si lo encuentra. No lo lo agrega.
          let buscarEmpaque = {};
          buscarEmpaque = this.getPaquetes.find(
            (element) => element.tipoemp == this.getUltNumart.tipoemp
          );
          // console.log("buscarEmpaque", buscarEmpaque)

          if (buscarEmpaque == undefined) {
            console.log("Caso 2. Nuevo tipo de producto");

            // Empujar al nuevo
            if (this.AddCantidad == false) {
              // this.paquetes.push({'CostoEnvio':CostoEnvio, tipoemp:this.getUltNumart.tipoemp})
              this.setPaquetes({
                CostoEnvio: CostoEnvio,
                tipoemp: this.getUltNumart.tipoemp,
                cantActTipoEmp: 1,
                cantMax: nCantMaxTipEmp,
                cantPaq: 1,
              });
            }
          } else {
            //CUANDO SE AGREGA UN PRODUCTO NUEVO no manda señal para sumar.
            // console.log("addcantidad", this.AddCantidad);
            // console.log("removecant", this.removeCant);
            // console.log("manuel was here");

            let nCantActTipEmp = 0;
            // Incrementar la cantidad de empaque
            this.getPaquetes.forEach((element) => {
              if (element.tipoemp === this.getUltNumart.tipoemp) {
                //Add desde otra vista. Inician ambas en false.
                if (this.AddCantidad === false && this.removeCant === false) {
                  // console.log("add", element);
                  element.cantActTipoEmp = element.cantActTipoEmp;
                  nCantActTipEmp = element.cantActTipoEmp;
                }

                //REMOVE
                if (this.removeCant === true) {
                  // console.log("removeCant", this.getUltNumart);
                  element.cantActTipoEmp = element.cantActTipoEmp - 1;
                  nCantActTipEmp = element.cantActTipoEmp;
                }
                //ADD
                if (this.AddCantidad === true) {
                  // console.log("add", element);
                  element.cantActTipoEmp = element.cantActTipoEmp + 1;
                  nCantActTipEmp = element.cantActTipoEmp;
                }

                nCantActTipEmp = element.cantActTipoEmp;

                // Validar si llego al maximo.
                // console.log("maximo por tipo", nCantMaxTipEmp);
                // console.log("cant  por tipo Actual", nCantActTipEmp);

                // if (nCantMaxTipEmp < nCantActTipEmp){
                //   console.log("Avisar que se lleno la caja. Crear otro paquete. ")
                //   element.cantPaq =Math.ceil(element.cantActTipoEmp/element.cantMax)
                // }
                if (element.cantMax === 0 || element.cantMax === undefined) {
                  element.cantMax = 1;
                }
                element.cantPaq = Math.ceil(
                  element.cantActTipoEmp / element.cantMax
                );
              }

              // console.table(this.getPaquetes);
            });
          }
        }

        let nCostoEnvio = 0.0;
        // const paquetesActuales = this.getPaquetes;
        // console.table (paquetesActuales)
        this.getPaquetes.forEach((element) => {
          nCostoEnvio = nCostoEnvio + element.CostoEnvio * element.cantPaq;
        });
        this.preciobase = nCostoEnvio.toFixed(2);
        // console.log("paquetes",this.getPaquetes)
      } //Termina redleaf

      // console.table(this.getPaquetes)
      // Modificar precio a ENVIO
      this.setCostoEnvio(this.preciobase);
      this.formarCarrito();
      // this.formarpaquetes()
    },

    getNumclisuc(item) {
      this.numclisuc = item.numclisuc;
    },

    // RECALCULAR AL BORRAR.
    recalcularDel(tipoempDel) {
      this.delPaquete(tipoempDel);

      let nCostoEnv = 0;
      this.getPaquetes.forEach((element) => {
        nCostoEnv = nCostoEnv + element.CostoEnvio * element.cantPaq;
      });

      this.setCostoEnvio(nCostoEnv);
    },

    iracarrito() {
      this.$router.push({ name: "carrito" }).catch((err) => {
        console.log(err);
      });
    },

    irhome() {
      this.$router.push({ name: "home" }).catch((err) => {
        console.log(err);
      });
    },

    limpiar() {
      // Limpiamos el carrito
      const value = [];
      this.limpiarCarrito(value);
      this.recalcular();
      this.$router.push({ name: "home" }).catch((err) => {
        console.log(err);
      });
    },

    add(articulo) {
      this.removeCant = false;
      // Ciclo para Recorrer getCarrito.
      // Si el articulo ya está en carrito, solo se incrementa su cantidad.
      for (var i = this.getCarrito.length - 1; i >= 0; i--) {
        if (this.getCarrito[i].clave == articulo.clave) {
          this.getCarrito[i].cantidad = this.getCarrito[i].cantidad + 1;
        }
      }
      //Se libera Bandera
      this.AddCantidad = true;
      this.setultnumart(articulo);
      this.recalcular();
      this.AddCantidad = false;
    },

    remove(articulo) {
      // QUITAR EL PRODDUCTO
      for (var i = this.getCarrito.length - 1; i >= 0; i--) {
        if (this.getCarrito[i].clave == articulo.clave) {
          this.getCarrito[i].cantidad = this.getCarrito[i].cantidad - 1;
          // Si es cero Borrar.
          if (this.getCarrito[i].cantidad == 0) {
            this.getCarrito.splice(i, 1);
            console.log(
              "tipo de empaque a Elimniar. Revisando.",
              articulo.tipoemp
            );
            this.recalcularDel(articulo.tipoemp);
          }
        }
      }

      this.removeCant = true;
      //Se libera Bandera
      this.setultnumart(articulo);
      this.recalcular();
      this.removeCant = false;

      // SI SOLO QUEDA EL ENVIO  Validar... pendiente
      if (this.getCarrito.length == 1) {
        this.limpiar();
      }

      // CALCULAR TOTALES
      this.formarCarrito();
    },
  },
};
</script>
