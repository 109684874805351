import Vue from 'vue'
import Vuex from 'vuex'

// import axios from 'axios'
// import VueAxios from 'vue-axios'
import VueJwtDecode from 'vue-jwt-decode'

// import rutas    from './router'
import router from '@/router'

import Login from '@/modules/Login/Login'
import Registro from '@/modules/Login/Registro'

import carrito from '@/modules/Carrito/carrito'

import articulos from '@/modules/Articulos/articulos'
import direccion from '@/modules/Perfil/direccion'
import tema from '@/modules/tema'

import calculoEnvios from '@/modules/calculos/calculoEnvios'
import tipoemp from '@/modules/calculos/tipoemp'

import documento from '@/modules/documento'
import relacionados from '@/modules/Relacionados/relacionados'
import config from '@/modules/config'

import snackbar from '@/modules/snackbar'
import direnvio from '@/modules/direnvio'


Vue.use(Vuex)
Vue.use(VueJwtDecode)

export default new Vuex.Store({
  state: {
	    token: null,
	    usuario: null,
	    nivel: null,
	    drawer: true,
	    menu: true,
	    tipomoneda: 'P',
	    precioutil: '2'
	  },

  mutations: {
	    setToken (state, token) {
	    	// console.log('recibo token', token)
	      state.token = token
	    },
	    setUsuario (state, usuario) {
	    	// console.log('serUsuario', usuario)
	      state.usuario = usuario
	    },
	    setNivel (state, nivel) {
	    	// console.log('setNivel', nivel)
	      state.nivel = nivel
	    },

	    setDenominacion (state, tipomoneda) {
	      state.tipomoneda = tipomoneda
	    },

	    SET_PRECIOUTIL (state, precioutil) {
	    	state.precioutil = precioutil
	    }
  },

  actions: {
	  setPrecioutil ({ commit }, precioutil) {
    	commit('SET_PRECIOUTIL', precioutil)
    },

    // Se manda llamar desde Login.vue
    guardarToken ({ commit }, token) {
    	// Guardar token
      commit('setToken', token)
      // Colocar Token en LocalStorage
      localStorage.setItem('tlkey', token)

     	// Decifrar Usuario
     	var decode = VueJwtDecode.decode(token)
     	var UsuarioActivo = decode.id
     	// Hacer commit para guardar Usuario
     	commit('setUsuario', UsuarioActivo) // Decodifica el token para sacar usuario
    },

    guardarNivel ({ commit }, nivel) {
    	commit('setNivel', nivel)
    },

    autoLogin ({ commit }) {
    },

    salir ({ commit }) {
	      commit('setUsuario', '')
	      commit('setToken', '')
	      localStorage.removeItem('tlkey')
	        // var tokenGen = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NjIyNTg4NjgsImlkIjoiYWRtaW4iLCJvcmlnX2lhdCI6MTU2MjI1NTI2OH0.SWGD5aH16otmX_cu-EaQ9OlgnLwV-WxbAuy85LUS1bE';
	      // localStorage.tlkey  = JSON.stringify(tokenGen);
	      router.go('/')
    }
  },

  getters: {
    // traeNomuser(state){
    // 	return state.usuario
    // },
    // traeNivel(state){
    // 	return state.nivel
    // },
    // getPrecioutil(state){
    // 	return state.precioutil
    // }

  },

  modules: {
    Login,
    Registro,
    articulos,
    carrito,
    direccion,
    tema,
    calculoEnvios,
    documento,
    relacionados,
    config,
    snackbar,
    tipoemp,
    direnvio
  }

})
